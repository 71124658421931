 //location of use: pharmacy create shifts create-pharmacy-shifts.html
 .timepicker-overlay {
    z-index: 1100 !important;
  }
  .timepicker-backdrop-overlay {
    z-index: 1100 !important;
  }
  .timepicker {
    .timepicker__header {
      background-color: #FF2D80 !important;
    }
  
    .clock-face__clock-hand {
      background-color: #FF2D80 !important;
  
      &::after {
        background-color: #FF2D80 !important;
      }
  
      &::before {
        border-color: #FF2D80 !important;
      }
    }
    .clock-face__number {
      > span.active {
        background-color: #FF2D80 !important;
      }
    }
    button:not(.timepicker-dial__item) {
      color: #FF2D80 !important;
    }
  }