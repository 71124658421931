.cdk-overlay-container * {
  app-mat-custom-select .mat-select-panel-wrap.ng-trigger.ng-trigger-transformPanelWrap {
    .ng-trigger.ng-trigger-transformPanel.mat-select-panel.mat-primary.primary-select {
      max-height: unset;
      .mat-option {
        text-transform: uppercase;
        background-color: #F2F2F2;
        font-size: 13px;
        font-weight: 500;
        color: #3E3E3E;
      }
    }
  }
}

app-mat-custom-select  .mat-form-field {
  height: 40px;
  width: 144px;
  &.light {
    height: initial;
    width: 100%;

    .mat-form-field-wrapper {
      background: none;

      button {
        background: none;
      }

      textarea.mat-input-element {
        margin: 12px 0;
      }

      .mat-form-field-wrapper {
        padding: initial;
      }

      .mat-form-field-underline {
        width: calc(100% - 32px);
      }
      .mat-form-field-subscript-wrapper {
        width: calc(100% - 32px);
      }
      .mat-form-field-underline {
        display: initial;
      }
      .mat-form-field-flex {
        .mat-form-field-infix {
          input[type='text'], input[type='password'], input[type='email'] {
            border-bottom: none;
            padding: 0;
            &:focus {
              background-color: initial;
              border-radius: initial;
              border: initial;
            }
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: initial;
              opacity: initial; /* Firefox */
            }
          }
        }
      }
    }
  }

  .mat-form-field-wrapper {
    padding: calc(1.5 * var(--gap-unit)) calc(2 * var(--gap-unit));
    height: 100%;
    box-sizing: border-box;
    background-color: #E4E4E4;
    .mat-form-field-underline {
      display: none;
    }
    .mat-form-field-flex {
      padding: 0;
      background: transparent;
      .mat-form-field-infix {
        margin: 0;
        padding: 0;
        height: 100%;
        border: 0;
        align-items: center;
        display: flex;
        .mat-select-trigger {
          .mat-select-value .mat-select-value-text {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            color: #3E3E3E;
          }
          .mat-select-arrow-wrapper {
            transform: unset;

          }
          .mat-select-value {
            background: transparent;
          }
        }
      }
    }
  }
  &.mat-focused.mat-primary .mat-select-arrow {
    color: #3E3E3E;
  }
}

.mat-select-panel.primary-select {
  margin-top: 30px;
  border-radius: 0;
}