@import "global";
@import "components/button";
@import "components/inputs";
@import "components/mat-selects";
@import "components/datatable";
@import "flex";

.title {
  font-size: 22px;
  color: var(--title-black);
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
  &.small {
    font-size: 12px;
  }
  &.medium {
    font-size: 16px;
  }
}

.primary-link {
  color: var(--text-black);
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.error {
  color: var(--error-color);
  font-size: 14px;
}

*:focus {
  outline: none;
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.cdk-global-overlay-wrapper{
  display: flex;
  .cdk-overlay-pane.app-dialog {
    max-width: 100% !important;
  }
}


