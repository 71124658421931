button{
  &.primary{
    width: 320px;
    height: 48px;
    background: var(--text-black);
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    outline: unset;
    border: 0;
    &:disabled{
      background-color: #EBEBEB;
      color: #D6D6D6;
    }
  }
}
