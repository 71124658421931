.ag-grid-datatable-primary {
  font-size: 13px;
  font-weight: 500;
  .ag-root-wrapper {
    border: 0;
    .ag-header {
      background: transparent;
      border: 0;
      .ag-header-cell {
        .ag-header-cell-resize {
          display: none;
        }
        .ag-header-cell-label {
          justify-content: space-around;
          .ag-header-cell-text {
            color: #6F6F6F;
          }
        }
        &.link {
          color: var(--text-link-pink);
        }
      }
    }
    .ag-center-cols-viewport {
      overflow-x: hidden;
      .ag-center-cols-container {
        .ag-row {
          border: 0;
          background: transparent;
          border-bottom: 1px solid var(--table-border-color);
          .ag-cell {
            font-weight: 500;
            color: #000;
            padding: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            &.link {
              color: var(--text-link-pink);
            }
          }
          &.ag-row-selected{
            background-color: #EFEFEF;
          }
        }
      }
    }
  }
}
