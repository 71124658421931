input[type='text'], input[type='password'], input[type='email'] {
  &.primary {
    height: 48px;
    width: 320px;
    color: var(--text-black);
    border: 0;
    border-bottom: 1px solid #e6e6e6;
    background: transparent;
    font-weight: 400;
    font-size: 16px;
    padding: 0 16px;
    box-sizing: border-box;
    &:focus {
      background-color: #F7F7F7;
      border-radius: 8px;
      border: 0;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9C9C9C;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9C9C9C;
    }

    &:-ms-input-placeholder { /* Microsoft Edge */
      color: #9C9C9C;
    }
  }
}
