// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ag-grid-community/styles//ag-grid.css";
@import "~ag-grid-community/styles//ag-theme-alpine.css";
@import './app/dialogs/pharmacy/create-pharmacy-shifts/custom-ngx-timepicker.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Chemist2U-New-Admin-Dashboard-primary: mat-palette($mat-indigo);
$Chemist2U-New-Admin-Dashboard-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Chemist2U-New-Admin-Dashboard-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Chemist2U-New-Admin-Dashboard-theme: mat-light-theme(
  (
    color: (
      primary: $Chemist2U-New-Admin-Dashboard-primary,
      accent: $Chemist2U-New-Admin-Dashboard-accent,
      warn: $Chemist2U-New-Admin-Dashboard-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($Chemist2U-New-Admin-Dashboard-theme);

/* You can add global styles to this file, and also import other style files */
@import "assets/template/template";
@import "assets/template/variables";

html,
body {
  width: 100%;
  height: 100%;
  display: flex;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

